import {
    Box, Text, Image, Button, IconButton, HStack, VStack, Divider, Flex, Input, useDisclosure, Modal, ModalContent, ModalHeader, ModalCloseButton, ModalFooter, ModalBody, ModalOverlay,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    MenuButton,
    MenuItem,
    Menu,
    MenuList,
    Spinner,
} from "@chakra-ui/react";
import { FaTrash, FaPlus, FaMinus } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import Header from "./Header";
import Footer from "./Footer";
import imagecart from "../IMG/emptycart1.webp";
import { Link, useNavigate } from "react-router-dom";
import Map from "./Map";
import logo2 from "../IMG/logo.jpeg"
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

function Cart() {
    const [cart, setCart] = useState([]);
    const [total, setTotal] = useState(0);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const navigate = useNavigate();
    const [delivery_charge, setDeliveryCharge] = useState({})
    const [deliveryPrice, setDeliveryPrice] = useState(0)
    const [coupon, setCoupon] = useState();
    const [isCouponApplied, setIsCouponApplied] = useState(false);
    const { isOpen: isModal1Open, onOpen: openModal1, onClose: closeModal1 } = useDisclosure();
    let [allAdd, setAdd] = useState([]);
    let login = JSON.parse(localStorage.getItem("login")) || "";
    let [latitude, setLatitude] = useState();
    let [longitude, setLongitude] = useState();
    let selectedAddress = JSON.parse(localStorage.getItem("clickedAddress")) || ""

    const fetchDelivery = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/delivery`)
            const res = await response.json();
            console.log(res.data);
            if (res.data.length === 0) {
                setDeliveryPrice(0)
                setDeliveryCharge(0)
            }
            setDeliveryCharge(res.data)
            setDeliveryPrice(res.data.delivery_price)
        } catch (error) {
            console.log(error);
        }
    }

    const handlecloseMain = () => {
        console.log("hi");
        onClose();
    }

    useEffect(() => {
        const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
        setCart(storedCart);
        calculateTotal(storedCart);

    }, []);

    const handleIncrement = (index) => {
        const updatedCart = [...cart];
        updatedCart[index].quantity++;
        setCart(updatedCart);
        localStorage.setItem("cart", JSON.stringify(updatedCart));
        calculateTotal(updatedCart);
        setIsCouponApplied(false);
    };

    const handleDecrement = (index) => {
        const updatedCart = [...cart];
        if (updatedCart[index].quantity > 1) {
            updatedCart[index].quantity--;
            setCart(updatedCart);
            localStorage.setItem("cart", JSON.stringify(updatedCart));
            calculateTotal(updatedCart);
            setIsCouponApplied(false);
        }
    };

    const handleRemove = (index) => {
        const updatedCart = cart.filter((_, idx) => idx !== index);
        setCart(updatedCart);
        localStorage.setItem("cart", JSON.stringify(updatedCart));
        calculateTotal(updatedCart);
        setIsCouponApplied(false);
    };

    const calculateTotal = (cartItems) => {
        const totalPrice = cartItems.reduce((acc, item) => acc + (item.sale_price * item.quantity), 0);
        setTotal(totalPrice);
    };

    const validateCouponCheck = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/couponequip/check/${coupon}`);
            const couponData = await response.json();
            console.log(couponData)
            if (response.ok) {
                let newPrice = total;
                console.log(couponData.couponData.percentage_offer)
                if (couponData.couponData.percentage_offer) {
                    newPrice = newPrice - (newPrice * (couponData.couponData.percentage_offer / 100));
                } else if (couponData.couponData.price_offer) {
                    newPrice = newPrice - couponData.couponData.price_offer;
                }

                newPrice = newPrice > 0 ? newPrice : 0;

                setTotal(newPrice);
                setIsCouponApplied(true);
                toast.success('Coupon applied');
            } else if (response.status === 404) {
                toast.error('Invalid Coupon');
            }
        } catch (error) {

        }
    }

    let fetchAddress = async () => {
        try {
            let response = await fetch(`https://100billionoms.top/address/${login.emplyee_ID}`)
            let res = await response.json();
            console.log(res.user)
            setAdd(res.user)
            // setAdd({...allAdd, ...res.user})
        } catch (error) {
            console.log(error)
        }

    }

    const handleClickAddressMobile = async (id) => {
        try {
            // Update the default address
            await updateDefaultAddress(id);

            // Find the clicked address from allAdd array
            const clickedAddress = allAdd.find(address => address.id === id);

            // Serialize the clicked address object before storing in local storage
            const serializedAddress = JSON.stringify(clickedAddress);

            // Store the serialized address in local storage
            localStorage.setItem('clickedAddress', serializedAddress);

            // Close the modal
            navigate("/cart");

            // Perform any other action needed when clicking on an address
        } catch (error) {
            console.log(error);
        }
    };


    const updateDefaultAddress = async (id) => {
        try {
            // Set all addresses' defaultaddress to false
            setAdd(prevState =>
                prevState.map(address => {
                    return { ...address, defaultaddress: false };
                })
            );

            // Update the clicked address to default
            const response = await fetch(`https://100billionoms.top/address/${id}`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ defaultaddress: true }),
            });

            if (response.ok) {
                // Update the default address in the state
                setAdd(prevState =>
                    prevState.map(address => {
                        if (address.id === id) {
                            return { ...address, defaultaddress: true };
                        } else {
                            return address;
                        }
                    })
                );
                console.log(allAdd)
                console.log("Default address updated successfully.");
            } else {
                console.log("Failed to update default address.");
            }
        } catch (error) {
            console.log(error);
        }
    };


    const handleClickAddress = async (id) => {
        try {
            // Update the default address
            await updateDefaultAddress(id);

            // Find the clicked address from allAdd array
            const clickedAddress = allAdd.find(address => address.id === id);

            // Serialize the clicked address object before storing in local storage
            const serializedAddress = JSON.stringify(clickedAddress);

            // Store the serialized address in local storage
            localStorage.setItem('clickedAddress', serializedAddress);

            // Close the modal
            onClose();

            // Perform any other action needed when clicking on an address
        } catch (error) {
            console.log(error);
        }
    };

    const handleaddressnext = () => {
        openModal1();
        onClose();
    }




    let handleDetect = () => {
        if (!navigator.geolocation) {
            // Geolocation is not supported by the browser
            console.log("Geolocation is not supported by your browser.");
            return;
        }

        // Try to get the current position
        // setLoad(false);
        navigator.geolocation.getCurrentPosition(
            (position) => {
                console.log(position.coords);
                setLongitude(position.coords.longitude);
                setLatitude(position.coords.latitude);
                localStorage.setItem("lat", position.coords.latitude)
                localStorage.setItem("lon", position.coords.longitude)
                const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&sensor=false&key=AIzaSyD0AcxoBWhqkMIAfK-F-X6ya3Es-52o1YU`;
                fetch(url)
                    .then((res) => res.json())
                    .then((res) => {
                        console.log(res);
                        // calculateRoute(res.results[0].formatted_address);
                        // // setAddress(res.results[0].formatted_address);
                        localStorage.setItem("address", JSON.stringify(res.results[0].formatted_address))
                        // setLoad(true);
                    })
                    .catch((error) => {
                        console.log(error);
                        // setLoad(false);
                    });
            },
            () => {
                // If the user denied geolocation access
                console.log("Please allow geolocation access to detect your location.");
                // setLocationWarning(true);
                // openModal4()
                // setLoad(true); // Reset the loading state
            }
        );
    };

    const handleCheckOut = async () => {
        console.log("clicked")
        try {
            const response = await fetch(`http://localhost`)
        } catch (error) {
            console.log(error);
        }
    }

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script);
        })
    };

    // const handleClick = async () => {
    //     // onClose();
    //     if (login) {
    //         try {
    //             let orderId = "11" + Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));

    //             // Load the Razorpay SDK
    //             let res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

    //             if (!res) {
    //                 alert("Razorpay SDK Failed to load. Are you online?");
    //                 return;
    //             }


    //             let amountInPoints = (total + deliveryPrice)
    //             let paymentRes = {
    //                 address: selectedAddress,
    //                 user_id: login.emplyee_ID,
    //                 order_id: orderId,
    //                 amount: amountInPoints * 100,
    //                 currency: "INR",
    //                 payment_capture: 1,
    //                 cart_products: cart,
    //                 // payment_mode: "Pay On Delivery"

    //             };

    //             // Make a POST request to your server to create payment order
    //             let result = await axios.post(`https://100billionoms.top/payment`, paymentRes);
    //             console.log(result)
    //             // Check if the response is successful
    //             if (result.data.isError) {
    //                 alert("Server error. Are You Online");
    //                 return;
    //             }

    //             let options = {
    //                 key: "rzp_test_zEWr4dOpfx4w2F",
    //                 currency: result.data.data.currency,
    //                 amount: result.data.data.amount,
    //                 order_id: result.data.data.id,
    //                 name: "Radical Sports India",
    //                 description: "Test Transaction",
    //                 image: logo2,
    //                 handler: async function (response) {
    //                     console.log("Razorpay payment response:", response);
    //                     if (response.razorpay_payment_id) {
    //                         // Redirect to the success page
    //                         localStorage.removeItem("cart")
    //                         window.location.href = `/cart`; // Replace "/success-page" with the URL of your success page
    //                     } else {
    //                         // Handle payment failure or cancellation
    //                         // You can redirect to a different page or show an error message
    //                         alert("Payment was not successful. Please try again.");
    //                         window.location.href = "/error-page"; // Redirect to an error page
    //                     }
    //                 },
    //                 prefill: {
    //                     email: "pankajsbisht143@gmail.com",
    //                     contact: "9021733853"
    //                 },
    //                 notes: {
    //                     address: "Razorpay Corporate Office"
    //                 },
    //                 theme: {
    //                     color: "#1f5215"
    //                 }
    //             };

    //             // Initialize Razorpay payment object and open checkout
    //             let paymentObject = new window.Razorpay(options);
    //             paymentObject.open();
    //             console.log(options)
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     } else {
    //         navigate("/login");
    //     }
    // };

    const handleClick = async () => {
        try {
            let orderId = "11" + Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));

            // Load the Razorpay SDK
            let res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

            if (!res) {
                alert("Razorpay SDK Failed to load. Are you online?");
                return;
            }

            // Define payment details
            let amountInPoints = (total + deliveryPrice)
            let paymentDetails = {
                address: selectedAddress,
                user_id: login.emplyee_ID,
                order_id: orderId,
                amount: amountInPoints * 100,
                currency: "INR",
                payment_capture: 1,
                cart_products: cart,
                // payment_mode: "Pay On Delivery"

            };

            // Create order options
            let options = {
                key: "rzp_test_zEWr4dOpfx4w2F",
                currency: paymentDetails.currency,
                amount: paymentDetails.amount,
                name: "Divya Drishti",
                description: "Test Transaction",
                handler: async function (response) {

                    if (response.razorpay_payment_id) {
                        // Payment was successful
                        paymentDetails.razorpay_payment_id = response.razorpay_payment_id;
                        paymentDetails.status = 'paid';
                        paymentDetails.orderStatus = 'failed';
                        let result = await axios.post(`https://100billionoms.top/payment`, paymentDetails);

                        if (!result.data.isError) {
                            // Redirect to the success page
                            localStorage.removeItem("cart");
                            navigate(`/thankyou/${paymentDetails.order_id}`);
                            // window.location.href = `/delivery/${orderId}`; // Replace "/success-page" with the URL of your success page
                        } else {
                            alert("Server error. Are You Online");
                            window.location.href = "/error-page"; // Redirect to an error page
                        }
                    } else {
                        // Handle payment failure or cancellation

                        paymentDetails.status = 'failed';
                        paymentDetails.orderStatus = 'failed';
                        await axios.post(`https://100billionoms.top/payment`, paymentDetails);
                        window.location.href = "/error-page"; // Redirect to an error page
                    }
                },
                prefill: {
                    email: "pankajsbisht143@gmail.com",
                    contact: "9021733853"
                },
                notes: {
                    address: "Razorpay Corporate Office"
                },
                theme: {
                    color: "#1f5215"
                },
                modal: {
                    ondismiss: function () {

                    },
                },
            };

            // Initialize Razorpay payment object and open checkout
            let paymentObject = new window.Razorpay(options);
            paymentObject.on('payment.failed', function (response) {

                paymentDetails.status = 'failed';
                paymentDetails.orderStatus = 'failed';
                axios.post(`https://100billionoms.top/payment`, paymentDetails)
                    .then(() => {
                        window.location.href = "/error-page"; // Redirect to an error page
                    })
                    .catch(error => {

                        window.location.href = "/error-page"; // Redirect to an error page
                    });
            });

            paymentObject.open();

        } catch (error) {
            console.log(error);
        }
    };


    let handleDelete = async (id) => {
        try {
            let response = await fetch(`https://100billionoms.top/address/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                }

            });
            if (response.ok) {
                // Remove the deleted address from the state
                setAdd(prevState => prevState.filter(address => address.id !== id));
                console.log("Address deleted successfully.");
            } else {
                console.log("Failed to delete address.");
            }
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        fetchDelivery();
        handleDetect();
        fetchAddress();
    }, [])



    return (

        <>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            <Box>

                <Header />
                <Box mt={"5rem"} p={4} w={{ base: "100%", sm: "100%", md: "100%", lg: "80%", xl: "80%" }} mx="auto">
                    <Drawer
                        isOpen={isOpen}
                        placement='right'
                        onClose={onClose}
                        size={{ base: "full", sm: "full", md: "full" }}
                    // finalFocusRef={btnRef}
                    >
                        <DrawerOverlay />
                        <DrawerContent >
                            <DrawerCloseButton />
                            <DrawerHeader boxShadow={"none"} color={"black"} borderBottom={"1px solid #eee"}><i class="fa-solid fa-arrow-left" style={{ marginRight: "15px" }} onClick={handlecloseMain}></i> Select Address</DrawerHeader>

                            <DrawerBody mt={"70px"}>
                                <Box borderRadius={"10px"} bg={"white"} p={"0px 12px 12px 12px"} cursor={"pointer"} >
                                    <Flex alignItems={"center"} color={"#1e90ff"} gap={"10px"} fontWeight={"bold"}>
                                        <i class="fa-solid fa-plus"></i>
                                        <Text fontSize={"15px"} onClick={() => navigate("/address")}>Add a new address</Text>
                                    </Flex>
                                </Box>
                                <Box m={"16px 0px 0px"}>
                                    <Text color={"#666666"} fontSize={"13px"}>Your saved address</Text>
                                </Box>
                                {
                                    allAdd.map((ele, idx) => {
                                        return (
                                            <Box key={idx} p={"12px"} m={"12px 0px"} bg={"white"} cursor={"pointer"}
                                                _hover={{ background: '#ebeced' }}

                                            >
                                                <Flex gap={"10px"} >
                                                    <Box onClick={() => handleClickAddress(ele.id)}>
                                                        {
                                                            ele.location_user === null ? <i class="fa-solid fa-house-user"></i> : null
                                                        }
                                                        {
                                                            ele.location_user === "Home" && <i className="fa-solid fa-home"></i>
                                                        }
                                                        {
                                                            ele.location_user === "Work" && <i class="fa-solid fa-briefcase"></i>
                                                        }
                                                        {
                                                            ele.location_user === "Other" && <i class="fa-solid fa-location-dot"></i>
                                                        }
                                                        {
                                                            ele.location_user === "Hotel" && <i className="fa-solid fa-building"></i>
                                                        }
                                                    </Box>
                                                    <Box>
                                                        <Text fontWeight={"600"} onClick={() => handleClickAddress(ele.id)} fontSize={"15px"}>{ele.location_user === null ? "Home" : ele.location_user}</Text>
                                                        <Text color={"#828282"} onClick={() => handleClickAddress(ele.id)} fontSize={"12px"}>{ele.name},{ele.flatno + " " + ele.area}</Text>
                                                        <Link to={`/address/${ele.id}`}><Box borderRadius={"50%"} cursor={"pointer"} display={{ base: "none", sm: "none", md: "none", lg: "block", xl: "block" }} w={"10%"} ><i style={{ margin: "auto" }} class="fa-solid fa-pen fa-xs"></i></Box></Link>
                                                        <Box borderRadius={"50%"} cursor={"pointer"} display={{ base: "block", sm: "block", md: "block", lg: "none", xl: "none" }} w={"5%"} >

                                                            <Menu borderRadius="none">
                                                                <MenuButton as={Text} >
                                                                    <Text >...</Text>
                                                                </MenuButton>
                                                                <MenuList borderRadius="none">
                                                                    <Link to={`/address/${ele.id}`}>  <MenuItem>Edit</MenuItem></Link>
                                                                    <MenuItem
                                                                        onClick={() => handleDelete(ele.id)}
                                                                    >Delete</MenuItem>
                                                                </MenuList>

                                                            </Menu>
                                                        </Box>
                                                    </Box>
                                                </Flex>
                                            </Box>
                                        )
                                    })
                                }
                            </DrawerBody>

                            <DrawerFooter>
                                {/* <Button variant='outline' mr={3} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='blue'>Save</Button> */}
                            </DrawerFooter>
                        </DrawerContent>
                    </Drawer>
                    {/* <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent mt={"40px"} >
                        <ModalHeader color={"black"}>Create your account</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody >
                          
                            <Box mt={"50px"}>
                                <Text>
                                    Although the phrase is nonsense, it does have a long history. The phrase has been used for several centuries by typographers to show the most distinctive features of their fonts.
                                </Text>
                            </Box>

                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme='blue' mr={3}>
                                Save
                            </Button>
                            <Button onClick={onClose}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal> */}
                    <Text fontSize="2xl" mb={4} fontWeight={"600"}>My Cart ({cart.length} items)</Text>
                    {
                        cart.length > 0 ? <Flex gap={"20px"} flexDirection={{ base: "column", sm: "column", md: "column", lg: "row", xl: "row" }}>
                            <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "60%", xl: "60%" }} mb={"10px"}>
                                {cart.map((item, index) => (
                                    <Box key={index} borderRadius={"10px"} boxShadow="rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;" mb={4} pb={4}>
                                        <Flex justifyContent={"space-between"} p={3} >
                                            <HStack spacing={4} >
                                                <Image src={`https://100billionoms.top${item.product_image}`} alt={item.name} boxSize="100px" objectFit="cover" />
                                                <VStack align="start" spacing={1}>
                                                    <Text fontWeight="500" fontSize="lg">{item.equipment_name}</Text>
                                                    <HStack>
                                                        <Text >₹{item.sale_price}</Text>
                                                    </HStack>
                                                    {/* Quantity Controls */}
                                                    <HStack spacing={2} mt={"10px"}>
                                                        <IconButton icon={<FaMinus />} bg={"#1e90ff"} color={"white"} _hover={{ background: "#1e90ff", color: "white" }}
                                                            size="sm" onClick={() => handleDecrement(index)} />
                                                        <Text fontWeight={"600"}>{item.quantity}</Text>
                                                        <IconButton icon={<FaPlus />} color={"white"} bg={"#1e90ff"} _hover={{ background: "#1e90ff", color: "white" }} size="sm" onClick={() => handleIncrement(index)} />
                                                    </HStack>
                                                </VStack>

                                            </HStack>
                                            <IconButton
                                                icon={<FaTrash />}
                                                onClick={() => handleRemove(index)}
                                                size="sm"
                                                colorScheme="red"
                                                float={"right"}
                                            />
                                        </Flex>
                                    </Box>
                                ))}
                            </Box>
                            {/* <Divider /> */}

                            {/* Price Details */}
                            <Box w={{ base: "100%", sm: "100%", md: "100%", lg: "40%", xl: "40%" }} p={4} boxShadow="rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;" borderRadius="md">
                                <Box >
                                    <Box mb={"30px"}>
                                        <Text fontSize={"xl"} fontWeight={"600"} textAlign={"center"}>Apply Coupon Code</Text>
                                        <Box w={"60%"} mt={"10px"} mx={"auto"}>
                                            <Flex gap={"10px"}>
                                                <Input name="coupon" value={coupon} onChange={(e) => setCoupon(e.target.value)} />
                                                {
                                                    isCouponApplied ?
                                                        <Button bg={"#1e90ff"} color={"white"} _hover={{ background: "#1e90ff", color: "white" }}
                                                        >
                                                            Applied
                                                        </Button> :
                                                        <Button bg={"#1e90ff"} color={"white"} _hover={{ background: "#1e90ff", color: "white" }}
                                                            onClick={validateCouponCheck} >
                                                            Apply
                                                        </Button>
                                                }
                                            </Flex>
                                        </Box>
                                    </Box>
                                    <Text fontSize="lg" fontWeight="500">Price Details</Text>
                                    <HStack justify="space-between" mt={2}>
                                        <Text>Total MRP</Text>
                                        <Text>₹{total}</Text>
                                    </HStack>
                                    <Divider mt={2} />
                                    <HStack justify="space-between" mt={2}>
                                        <Text>Delivery Charge</Text>
                                        <Text>₹{deliveryPrice}</Text>
                                    </HStack>
                                    <Divider mt={2} />
                                    <HStack justify="space-between" mt={2}>
                                        <Text fontSize="lg" fontWeight="500">Total Amount</Text>
                                        <Text fontSize="lg" fontWeight="500">₹{total + deliveryPrice}</Text>
                                    </HStack>

                                    <Box mt={"20px"}>
                                        {allAdd.length > 0 && selectedAddress && (
                                            <Box p={"0px 5px 0px 5px"}>
                                                <Flex alignItems={"center"} justifyContent={"space-between"}>
                                                    <Box>
                                                        <Flex alignItems={"center"} gap={"10px"}>
                                                            <Box>
                                                                <i className="fa-solid fa-location-dot"></i>
                                                            </Box>
                                                            <Box>
                                                                <Text fontWeight={"500"} color={"#000000"} fontSize={"13px"}>Delivering to home</Text>
                                                                <Text fontSize={"11px"}>Address is: {selectedAddress.area.slice(0, 40)}</Text>
                                                            </Box>
                                                        </Flex>
                                                    </Box>
                                                    <Box>
                                                        <Text color={"#1e90ff"} cursor={"pointer"} fontWeight={"bold"} fontSize={"12px"}
                                                            onClick={onOpen}
                                                        >Change</Text>
                                                    </Box>
                                                </Flex>
                                            </Box>
                                        )}


                                        {
                                            selectedAddress !== "" ? <Divider m={"10px"} bg={"black"} /> : null
                                        }
                                    </Box>
                                    <Button colorScheme="blue" w="full" onClick={() => { selectedAddress === "" ? onOpen() : handleClick() }}>Proceed to Checkout</Button>
                                </Box>
                            </Box>
                        </Flex> : <Box mb={"40px"}>
                            <Image display={"block"} margin={"auto"} src={imagecart} bg={"white"} />
                            <Text textAlign={"center"} color={"#1e90ff"} fontWeight={"500"} fontSize={"2xl"}>Your Cart is Empty!!!</Text>
                            <Box textAlign={"center"} mt={"20px"} >
                                <Button bg="#1e90ff" color="white" _hover={{ bg: "#1e90ff", color: "white" }} onClick={() => navigate("/equipment")}>BUY NOW</Button>
                            </Box>
                        </Box>
                    }
                </Box>
            </Box>
            <Footer />
        </>
    );
}

export default Cart;
