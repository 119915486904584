import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';
import Home from './Components/Home';
import { Routes, Route } from 'react-router-dom';
import Login from './Components/Login';
import Register from './Components/Register';
import Forgot from './Components/Forgot';
import Admission from './Components/AdmissionForm';
import Enquiry from './Components/EnquiryForm'
import Payment from './Components/Payment'
import Schedule from './Components/Schedule'
import Profile from './Components/Profile'
import Contact from './Components/Contact'
import About from './Components/About'
import Products from './Components/Products';
import Cart from './Components/Cart';
import Address from './Components/Address';
import EditAddress from './Components/EditAddress';
import AllRoute from './Components/AllRoute/AllRoute';
import ScrollToTop from './Components/ScrollToTop';
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';

function App() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>Details</ModalHeader> */}
          <ModalCloseButton />
          <ModalBody>
            This is beta version (testing version)
          </ModalBody>

          <ModalFooter>
            {/* <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant='ghost'>Secondary Action</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AllRoute />
      <ScrollToTop />
      {/* <button className="beta-button" onClick={onOpen}>BETA</button> */}
    </>
  );
}

export default App;
